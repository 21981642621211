import request from "@/api/request";

/**
 * 获取手机验证码
 * @param params
 * @returns {Promise<AxiosResponse<any>>}
 */
export async function getCode(params) {
    return await request.post("/user/getCode",params)
}

/**
 * 登录
 * @param params
 * @returns {Promise<AxiosResponse<any>>}
 */
export async function login(params) {
    return await request.post("/user/login",params)
}

/**
 * 注册
 * @param params
 * @returns {Promise<AxiosResponse<any>>}
 */
export async function register(params) {
    return await request.post("/user/register",params)
}

/**
 * 找回密码
 * @param params
 * @returns {Promise<AxiosResponse<any>>}
 */
export async function updateUser(params) {
    return await request.post("/user/updateUser",params)
}
