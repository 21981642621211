<template>
    <van-cell-group inset >
      <van-form @submit="onSubmit">
        <van-field name="mobile"
                   v-model="mobile"
                   type="digit"
                   maxlength="11"
                   label="手机号" placeholder="手机号"
                   :rules="[{ required: true, message: '请输入手机号' }]"/>
        <van-field
            name="authCode"
            v-model="authCode"
            center
            maxlength="6"
            type="digit"
            clearable
            label="短信验证码"
            placeholder="验证码"
            :rules="[{ required: true, message: '请输入验证码' }]">
          <template #button>
            <van-button v-show="authCodeShow" size="small" type="primary" @click="sendAuthCode" native-type="button"
                        style="width: 100px" :loading="loading">发送验证码
            </van-button>
            <van-button v-show="!authCodeShow" size="small" type="primary" native-type="button" style="width: 100px">
              {{ countDownTime }}s后重新发送
            </van-button>
          </template>
        </van-field>
        <van-field
            v-model="newPassword"
            type="password"
            name="newPassword"
            label="密码"
            maxlength="18"
            placeholder="请输入密码"
            :rules="[{ required: true, message: '请输入密码' }]"
        />
        <van-field
            v-model="password"
            type="password"
            name="password"
            label="确认密码"
            maxlength="18"
            placeholder="请再次输入密码"
            :rules="[{ required: true, message: '请再次输入密码' }]"
        />

        <div style="margin: 16px;">
          <van-button round block type="info" native-type="submit" :loading="registerLoading">提交</van-button>
        </div>
        <div style="margin: 16px;">
          <van-button round block native-type="button" @click="loginClick">已有账户，立即登录</van-button>
        </div>
      </van-form>
    </van-cell-group>
</template>

<script>
import {getCode, login, register, updateUser} from "@/api/login/login";

export default {
  name: "register",
  data() {
    return {
      registerLoading:false,
      loading:false,
      mobile: '',
      password: '',
      newPassword: '',
      authCode: '',
      authCodeShow: true,
      countDownTime: 0,
      timer: null,
      loginTypeTitle: '密码登录'
    };
  },
  components: {},
  methods: {
    async onSubmit(values) {

      if (!/\d{11}/.test(values.mobile)) {
        this.$toast("手机号输入有误")
        return false;
      }
      if (!/\d{6}/.test(values.authCode)) {
        this.$toast("请正确输入验证码")
        return false;
      }
      if (this.password !== this.newPassword) {
        this.$toast("两次输入密码不一致")
        return false;
      }
      this.registerLoading = true;
      let res = await updateUser(values);
      this.registerLoading = false;
      if (res) {
      this.$toast.success('找回成功');
      let this_ = this;
      setTimeout(async () => {
        this_.loginClick();
      }, 2000);
      }
    },
    async sendAuthCode() {
      if (this.mobile === '') {
        this.$toast("请输入手机号码")
        return false;
      }
      this.loading = true;
      let res = await getCode({
        mobile: this.mobile
      })
      this.loading = false;
      if (res) {
        this.$toast("发送成功");
        this.throttleFn();

      }
    },
    //函数节流
    throttleFn() {
      const TIME_COUNT = 60;
      if (!this.timer) {
        this.countDownTime = TIME_COUNT;
        this.authCodeShow = false;
        this.timer = setInterval(() => {
          if (this.countDownTime > 0 && this.countDownTime <= TIME_COUNT) {
            this.countDownTime--;
          } else {
            this.authCodeShow = true;
            clearInterval(this.timer);
            this.timer = null;
          }
        }, 1000)
      }
    },
    loginClick() {
      this.$emit('toLogin',2)
    }
  },
  mounted() {
    document.title = this.title
  },
}
</script>

<style scoped>

</style>
