<template>
  <transition name="van-slide-left">
    <div v-show="visible">
      <div style="width: 100%;text-align: center;margin: 100px 0 30px 0">
        <img src="@/assets/logo.png" style="width: 140px;height: 140px; border-radius: 100%;">
      </div>
      <van-divider content-position="left">捡穗账-{{ title }}</van-divider>
      <transition :name="transitionLogin">
        <van-cell-group inset v-show="loginShow" style="padding-top: 10px">
          <van-form @submit="onSubmit">
            <van-field name="mobile"
                       v-model="mobile"
                       type="digit"
                       maxlength="11"
                       label="手机号" placeholder="手机号"
                       :rules="[{ required: true, message: '请输入手机号' }]"/>
            <van-field
                v-show="passLoginShow"
                v-model="password"
                type="password"
                name="password"
                label="密码"
                maxlength="18"
                placeholder="请输入密码"
                :rules="[{ required: false, message: '请输入密码' }]"
            />
            <van-field
                v-if="!passLoginShow"
                name="authCode"
                v-model="authCode"
                center
                maxlength="6"
                type="digit"
                clearable
                label="短信验证码"
                placeholder="验证码"
                :rules="[{ required: true, message: '请输入验证码' }]">
              <template #button>
                <van-button v-show="authCodeShow" size="small" type="primary" @click="sendAuthCode" native-type="button"
                            style="width: 100px" :loading="loading">发送验证码
                </van-button>
                <van-button v-show="!authCodeShow" size="small" type="primary" native-type="button"
                            style="width: 100px">
                  {{ countDownTime }}s后重新发送
                </van-button>
              </template>
            </van-field>
            <div style="margin: 16px;">
              <van-button round block type="info" native-type="submit" :loading="loginLoading" loading-text="正在登录...">登
                录
              </van-button>
            </div>
          </van-form>
          <div style="margin: 10px 0 0 10px;">
            <p @click="cutLogin" style="font-size: 12px;color: #1989fa;text-decoration: underline;float: left">
              {{ loginTypeTitle }}</p>
          </div>
          <div style="margin: 10px 20px 0 0;">
            <p style="font-size: 12px;color: #1989fa;text-decoration: underline;float: right" @click="loginTypeClick(0)">
              注册新用户</p>
          </div>
        </van-cell-group>
      </transition>
      <div style="text-align: center;position: absolute;bottom: 10px;width: 100%;" v-show="retrieveTitleShow">
        <p style="font-size: 12px;color: #1989fa;text-decoration: underline;" @click="loginTypeClick(2)">
          找回密码</p>
      </div>
      <transition :name="transitionRegister">
        <div v-show="registerShow">
          <register @toLogin="toLogin"/>
        </div>
      </transition>
      <transition :name="transitionRetrieve">
        <div v-show="retrieveShow">
          <retrieve @toLogin="toLogin"/>
        </div>
      </transition>
    </div>
  </transition>
</template>

<script>


import {getCode, login} from "@/api/login/login";
import {getToken, getUserId, setToken, setUserId, setUserName} from "@/utils/authUtils";
import {isMobile} from "@/utils/terminalUtils";
import Register from "@/views/mobile/login/register";
import Retrieve from "@/views/mobile/login/retrieve";

export default {
  name: "login",
  data() {
    return {
      title: '登录',
      registerShow: false,
      loginShow: true,
      retrieveShow: false,
      transitionLogin: 'van-slide-left',
      transitionRegister: 'van-slide-right',
      transitionRetrieve: 'van-slide-right',
      retrieveTitleShow:true,
      visible: false,
      loginLoading: false,
      loading: false,
      mobile: '',
      password: '',
      authCode: '',
      authCodeShow: true,
      passLoginShow: false,
      countDownTime: 0,
      loginType: 0,
      timer: null,
      loginTypeTitle: '密码登录'
    };
  },
  components: {
    Register,
    Retrieve
  },
  methods: {
    async onSubmit(values) {
      values.loginType = this.loginType;
      if (!/\d{11}/.test(values.mobile)) {
        this.$toast("手机号输入有误")
        return false;
      }
      if (!this.passLoginShow) {
        if (!/\d{6}/.test(values.authCode)) {
          this.$toast("请正确输入验证码")
          return false;
        }
      } else {
        if (!this.password) {
          this.$toast("请输入密码")
          return false;
        }
      }
      this.loginLoading = true;
      let this_ = this;
      let res = await login(values);
      if (res) {
        setToken(res.token);
        setUserId(res.id);
        setUserName(res.realName);
        setTimeout(async () => {
          this.$toast.success('登录成功');
          this.loginLoading = false;
          await this_.$router.replace("/home");
        }, 2000);
      } else {
        this.loginLoading = false;
      }
    },
    cutLogin() {
      this.passLoginShow = !this.passLoginShow
      this.loginTypeTitle = this.passLoginShow ? '短信验证码登录' : '密码登录'
      this.loginType = this.passLoginShow ? 1 : 0
    },
    async sendAuthCode() {
      if (this.mobile === '') {
        this.$toast("请输入手机号码")
        return false;
      }
      this.loading = true;
      let res = await getCode({
        mobile: this.mobile
      })
      this.loading = false;
      if (res) {
        this.$toast("发送成功");
        this.throttleFn();
      }
    },
    //函数节流
    throttleFn() {
      const TIME_COUNT = 60;
      if (!this.timer) {
        this.countDownTime = TIME_COUNT;
        this.authCodeShow = false;
        this.timer = setInterval(() => {
          if (this.countDownTime > 0 && this.countDownTime <= TIME_COUNT) {
            this.countDownTime--;
          } else {
            this.authCodeShow = true;
            clearInterval(this.timer);
            this.timer = null;
          }
        }, 1000)
      }
    },
    loginTypeClick(val) {
      let this_ = this;
      this.retrieveTitleShow = false;
      if (val === 0) {
        this.transitionLogin = 'van-slide-left';
        this.loginShow = false;
        setTimeout(function () {
          this_.transitionRegister = 'van-slide-right';
          this_.registerShow = true;
          this_.title = '注册'
        }, 350)
      } else {
        this.transitionLogin = 'van-slide-left';
        this.loginShow = false;
        setTimeout(function () {
          this_.transitionRetrieve = 'van-slide-right';
          this_.retrieveShow = true;
          this_.title = '找回密码'
        }, 350)
      }
    },
    toLogin(val) {
      if (val === 0) {
        this.transitionRegister = 'van-slide-right';
        this.registerShow = false;
        this.retrieveShow = false;
      } else {
        this.transitionRetrieve = 'van-slide-right';
        this.retrieveShow = false;
        this.registerShow = false;
      }
      let this_ = this;
      setTimeout(function () {
        this_.retrieveTitleShow = true;
        this_.title = '登录'
        this_.transitionLogin = 'van-slide-left';
        this_.loginShow = true;
      }, 350)
    }
  },
  mounted() {
    this.visible = true;
    let userId = getUserId();
    let token = getToken();
    if (token && userId) {
      if (isMobile()) {
        this.$router.replace('/home');
      } else {
        this.$router.replace('/home-p');
      }
    }
    document.title = this.$route.meta.title
  },
}
</script>

<style scoped>

</style>
